import * as React from 'react';
import clsx from 'clsx';
import styles from './social.module.css';
import list from './list.json';

interface ISocial {
  socialList?: { title: string; to: string }[];
  customStyle?: string;
}

const Social: React.FC<ISocial> = ({ customStyle, socialList = list }) => {
  return (
    <div className={clsx(styles.container, customStyle)}>
      <ul className={styles.list}>
        {socialList.map((e) => (
          <li key={e.title.toLowerCase()} className={styles.item}>
            <a
              className={clsx(styles.link, styles[e.title.toLowerCase()])}
              href={e.to}
              rel="noopener noreferrer"
              aria-label={e.title}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Social;
