import React from 'react';
import { Helmet } from 'react-helmet';
import { getSchemaOrgJSONLD, IBlogMeta, IFaqMeta } from './schemaOrg';
import { meta } from 'metaData';
import useSiteMetadata from 'hooks/useSiteMetaData';

interface IImage {
  src: string;
  width: string;
  height: string;
}

export interface IMainMeta {
  title: string;
  description: string;
  pathname: string;
  image?: IImage;
  keywords?: Array<string>;
}

export interface IProps {
  mainMetaData: IMainMeta;
  blogMetaData?: IBlogMeta;
  faqMetaData?: IFaqMeta[];
}

const SEO = ({ mainMetaData = meta.main, blogMetaData, faqMetaData }: IProps) => {
  const { siteUrl } = useSiteMetadata();
  const url = `${siteUrl}${mainMetaData.pathname}`;

  return (
    <Helmet
      titleTemplate={`%s | Brocoders${blogMetaData ? ' blog about software development' : ''}`}
    >
      <meta charSet="utf-8" />
      <title itemProp="headline">{mainMetaData.title}</title>
      <meta itemProp="description" name="description" content={mainMetaData.description} />
      {mainMetaData.keywords && (
        <meta itemProp="keywords" name="keywords" content={mainMetaData.keywords.join(',')} />
      )}
      <meta property="og:title" content={mainMetaData.title} />
      {!blogMetaData ? (
        <meta property="og:type" content="website" />
      ) : (
        <meta property="og:type" content="article" />
      )}
      <meta property="og:description" content={mainMetaData.description} />
      <meta property="og:url" content={url} />
      {mainMetaData.image ? (
        [
          <meta property="og:image" content={mainMetaData.image.src} key="meta og image" />,
          <meta property="og:image:width" content={mainMetaData.image.width} key="width" />,
          <meta property="og:image:height" content={mainMetaData.image.height} key="height" />,
          <meta name="twitter:card" content="summary_large_image" key="twitter card" />,
        ]
      ) : (
        <meta name="twitter:card" content="summary" />
      )}
      {blogMetaData?.url && <link rel="canonical" href={url} />}
      {process.env.APP_ENV === 'dev' || process.env.APP_ENV === 'release' ? (
        <meta name="robots" content="noindex,nofollow" />
      ) : null}

      <link rel="dns-prefetch" href="https://assets.calendly.com" />
      {process.env.APP_ENV === 'prod' && (
        <>
          <link rel="dns-prefetch" href="https://snap.licdn.com" />
          <link rel="dns-prefetch" href="https://js.hs-scripts.com" />
          <link rel="dns-prefetch" href="https://js.hs-banner.com" />
          <link rel="dns-prefetch" href="https://js.hscollectedforms.net" />
          <link rel="dns-prefetch" href="https://js.hscollectedforms.net" />

          <link rel="preconnect" href="https://consent.cookiebot.com" />
          <link rel="preconnect" href="https://consentcdn.cookiebot.com" />
        </>
      )}
      <link rel="preconnect" href={`${process.env.GATSBY_STRAPI_CDN}`} />

      <script type="application/ld+json">{getSchemaOrgJSONLD(blogMetaData, faqMetaData)}</script>
    </Helmet>
  );
};

export default SEO;
