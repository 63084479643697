import logo from '../../images/logo.png';

export interface IYoutubeVideo {
  src_id: string;
  title?: string;
  description?: string;
  duration?: string;
  preview?: string;
}
export interface IBlogMeta {
  title: string;
  description: string;
  image: string;
  url: string;
  authorName: string;
  datePublished: string;
  averageRating: number;
  ratingCount: number;
  youTube: IYoutubeVideo;
}

export interface IFaqMeta {
  question: string;
  answer: string;
  id: number;
}

export const getSchemaOrgJSONLD = (blog?: IBlogMeta, faq?: IFaqMeta[]): string => {
  const STATIC_DATA = {
    title: 'Brocoders - Technical partner for startups from MVP to Exit',
    url: 'https://brocoders.com/',
    logo,
  };

  const schemaOrgMain = [
    {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: STATIC_DATA.title,
      url: STATIC_DATA.url,
      description:
        'We&rsquo;re here to accompany you all along the road from an MVP to a successful product stage or wherever your business goals and ambitions will take you.',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Baranivska str. 203',
        addressLocality: 'Sumy',
        postalCode: '40035',
        addressCountry: 'Ukraine',
      },
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '+38 050 768 76 91',
        },
        {
          '@type': 'ContactPoint',
          telephone: '+1 347 389 12 61',
        },
      ],
    },
    {
      '@context': 'http://schema.org',
      '@type': 'Place',
      geo: {
        '@type': 'GeoCoordinates',
        latitude: '50.945833',
        longitude: '34.861861',
      },
      hasMap: 'https://goo.gl/maps/Zu5vUEC1HL3M2ej29',
    },
    {
      '@context': 'http://schema.org',
      '@type': 'CivicStructure',
      openingHours: 'Mo, Tu, We, Th, Fr 01:00-01:00',
    },
  ];

  const schemaOrgBlogPost = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: blog?.title,
    name: blog?.title,
    alternateName: blog?.title,
    description: blog?.description,
    image: blog?.image,
    url: `${STATIC_DATA.url}${blog?.url}`,
    author: {
      '@type': 'Person',
      name: blog?.authorName,
    },
    publisher: {
      '@type': 'Organization',
      name: STATIC_DATA.title,
      logo: STATIC_DATA.logo,
    },
    mainEntityOfPage: {
      '@type': 'WebSite',
      '@id': STATIC_DATA.url,
    },
    datePublished: blog?.datePublished,
  };

  const schemaOrgFaq = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faq?.map((item) => ({
      '@type': 'Question',
      name: item.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.answer,
      },
    })),
  };

  const schemaOrgRating = {
    '@context': 'https://schema.org',
    '@type': 'CreativeWorkSeries',
    name: blog?.title,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: blog?.averageRating,
      reviewCount: blog?.ratingCount,
    },
  };

  const schemaOrgYoutube = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    url: `https://www.youtube.com/watch?v=${blog?.youTube?.src_id}`,
    name: blog?.youTube?.title,
    description: blog?.youTube?.description,
    duration: blog?.youTube?.duration,
    isFamilyFriendly: 'true',
    thumbnailUrl: blog?.youTube?.preview,
  };

  const schemaOrgData: any = [...schemaOrgMain];

  if (blog) {
    schemaOrgData.push(schemaOrgBlogPost);
  }
  if (faq) {
    schemaOrgData.push(schemaOrgFaq);
  }
  if (blog?.averageRating && blog?.ratingCount) {
    schemaOrgData.push(schemaOrgRating);
  }
  if (blog?.youTube) {
    schemaOrgData.push(schemaOrgYoutube);
  }

  return JSON.stringify(schemaOrgData);
};
